import MyMap from "../commonComponents/MyMap";
import info from "../info";

const {address, email, phone} = info
const Contact = () => (
    <div className="container-fluid py-5">
        <div className="container">
            <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
                <h5 className="text-primary">Mantente en Contacto</h5>
                <h1 className="mb-3">Contáctanos</h1>
            </div>
            <div className="py-5 contact-detail position-relative p-1">

                <div className="row g-5 mb-5 justify-content-center">

                    <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                        <div
                            className="d-flex justify-content-center align-items-center bg-light p-3 rounded h-100">

                            <div className="row w-100 h-100">
                                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">

                                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                                         style={{width: '64px', height: '64px'}}>
                                        <i className="fas fa-map-marker-alt text-white"></i>
                                    </div>

                                </div>
                                <div className="col-12 col-md-8 d-flex justify-content-center align-items-center">

                                    <div className="ms-3">
                                        <h4 className="text-primary">Dirección</h4>
                                        <a href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6" target="_blank"
                                           className="h5">
                                            {address}
                                        </a>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                        <div
                            className="d-flex justify-content-center align-items-center bg-light p-3 rounded h-100">

                            <div className="row w-100 h-100">
                                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">

                                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                                         style={{width: '64px', height: '64px'}}>
                                        <i className="fa fa-phone text-white"></i>
                                    </div>

                                </div>
                                <div className="col-12 col-md-8 d-flex justify-content-center align-items-center">

                                    <div className="ms-3">
                                        <h4 className="text-primary">Teléfono</h4>
                                        <a className="h5" href="tel:+0123456789" target="_blank">{phone}</a>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                        <div
                            className="d-flex justify-content-center align-items-center bg-light p-3 rounded h-100">

                            <div className="row w-100 h-100">
                                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">

                                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                                         style={{width: '64px', height: '64px'}}>
                                        <i className="fa fa-envelope text-white"></i>
                                    </div>

                                </div>
                                <div className="col-12 col-md-8 d-flex justify-content-center align-items-center">

                                    <div className="ms-3">
                                        <h4 className="text-primary">Correo</h4>
                                        <a className="h5" href="mailto:info@example.com" target="_blank">
                                            {email}
                                        </a>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="row g-5">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                        <div
                            className="p-2 h-100 rounded contact-map d-flex justify-content-center align-items-center">
                            <MyMap/>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                        <div className="p-2 rounded contact-form">
                            <div className="mb-4">
                                <input type="text" className="form-control border-0 py-3" placeholder="Nombre"/>
                            </div>
                            <div className="mb-4">
                                <input type="email" className="form-control border-0 py-3"
                                       placeholder="Correo Electrónico"/>
                            </div>
                            <div className="mb-4">
                                <input type="text" className="form-control border-0 py-3" placeholder="Empresa"/>
                            </div>
                            <div className="mb-4">
                            <textarea className="w-100 form-control border-0 py-3" rows="6" cols="10"
                                      placeholder="Mensaje"></textarea>
                            </div>
                            <div className="text-start">
                                <button className="btn bg-primary text-white py-3 px-5" type="button">
                                    Enviar Mensaje
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Contact